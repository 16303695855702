@tailwind base;
@tailwind components;
@tailwind utilities;

.apexcharts-legend {
  border-radius: 8px;
  height: fit-content;
  align-items: start;
  max-height: 80%;
}

.apexcharts-legend-series {
  line-height: 100% !important;
}

div.leaflet-overlay-pane svg {
  pointer-events: initial !important;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0) !important;
}

.leaflet-interactive {
  cursor: pointer;
}

.apexcharts-tooltip {
  white-space: pre-wrap !important;
  max-width: 420px;
}

.apexcharts-tooltip-marker {
  min-width: 12px !important;
}

.apexcharts-legend-series {
  text-align: left !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  min-height: max-content !important;
  background: transparent !important;
}

.leaflet-pane {
  width: 100%;
  height: 100%;
  z-index: 1 !important;
}

.leaflet-marker-pane {
  width: 0 !important;
  height: 0 !important;
}

.leaflet-shadow-pane {
  width: 0 !important;
  height: 0 !important;
}

.leaflet-tooltip-pane {
  width: 0 !important;
  height: 0 !important;
}

.leaflet-popup-pane {
  width: 0 !important;
  height: 0 !important;
}
